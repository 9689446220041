import HeroImage from 'assets/images/hero.png'
import YellowCircle from 'assets/images/yellowCircle.png'
import Ellipse1 from 'assets/landing-page/Ellipse1.png'
import Ellipse2 from 'assets/landing-page/Ellipse2.png'
import EnglishforMarineEngineers from 'assets/landing-page/EnglishforMarineEngineers.png'
import Firstdaysasacadet from 'assets/landing-page/Firstdaysasacadet.png'
import Livingonaship from 'assets/landing-page/Livingonaship.png'
import MaritimeTestsSrc from 'assets/landing-page/MaritimeTests.png'
import BlockchainShowcaseSrc from 'assets/landing-page/blockchain-showcase.png'
import ConnectGloballySrc from 'assets/landing-page/connect-globally.png'
import ContributionsSrc from 'assets/landing-page/contributions-aside.png'
import JobsSrc from 'assets/landing-page/jobs-aside.png'
import NewsSrc from 'assets/landing-page/news-aside.png'
import { Button } from 'common/components/Button/Button'
import DownloadApp from 'common/components/DownloadApp/DownloadApp'
import { generateBasicSeoTags } from 'common/utils/seo'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import IndividualsBlog from 'services/landing-page-individuals/components/IndividualsBlog'
import IndividualsFeatures from 'services/landing-page-individuals/components/IndividualsFeatures'
import IndividualsFooter from 'services/landing-page-individuals/components/IndividualsFooter'
import IndividualsHedera from 'services/landing-page-individuals/components/IndividualsHedera'
import IndividualsHero from 'services/landing-page-individuals/components/IndividualsHero'
import IndividualsJoinUs from 'services/landing-page-individuals/components/IndividualsJoinUs'
import ReviewsCarousel from 'services/landing-page-individuals/components/individualsCarousel'
import IndividualsNavbar from 'services/landing-page-individuals/components/individualsNavbar'
import styled from 'styled-components'

const LandingPageIndividuals = () => {
  const { t } = useTranslation('ladning-page-individuals')
  const featuresList = [
    {
      title: t('feature_1-title'),
      description: t('feature_1-description'),
      image: BlockchainShowcaseSrc,
    },
    {
      title: t('feature_2-title'),
      description: t('feature_2-description'),
      image: MaritimeTestsSrc,
      button: t('feature_2-button'),
      redirect: 'https://play.google.com/store/apps/details?id=com.seaplify',
    },
    {
      title: t('feature_3-title'),
      description: t('feature_3-description'),
      image: JobsSrc,
      button: t('feature_3-button'),
      redirect: 'https://play.google.com/store/apps/details?id=com.seaplify',
    },
    {
      title: t('feature_4-title'),
      description: t('feature_4-description'),
      image: ContributionsSrc,
      button: t('feature_4-button'),
      redirect: 'https://play.google.com/store/apps/details?id=com.seaplify',
    },
    {
      title: t('feature_5-title'),
      description: t('feature_5-description'),
      image: NewsSrc,
      redirect: 'https://play.google.com/store/apps/details?id=com.seaplify',
    },
    {
      title: t('feature_6-title'),
      description: t('feature_6-description'),
      image: ConnectGloballySrc,
      redirect: 'https://play.google.com/store/apps/details?id=com.seaplify',
      button: t('feature_6-button'),
    },
  ]

  const blogList = [
    {
      title: 'Importance of English in Marine Engineering',
      url: 'https://blog.seaplify.com/english-for-marine-engineers/',
      date: 'September 20, 2023',
      category: 'Career Development',
      readingTime: '6 min.',
      image: EnglishforMarineEngineers,
    },
    {
      title: 'Living on a ship: Essential guide to packing',
      url: 'https://blog.seaplify.com/living-on-a-ship-essential-guide-to-packing/',
      date: 'August 29, 2023',
      category: 'Guides',
      readingTime: '2 min.',
      image: Livingonaship,
    },
    {
      title: 'First days as a cadet on a ship: What to know',
      url: 'https://blog.seaplify.com/first-days-as-a-cadet-on-a-ship-what-to-know/',
      date: 'August 21, 2023',
      category: 'Career Development',
      readingTime: '4 min.',
      image: Firstdaysasacadet,
    },
  ]

  return (
    <div className="overflow-hidden">
      <Head>
        {generateBasicSeoTags({
          url: '/recruiters',
          title: t('landing-page-title'),
          description: t('landing-page-description'),
        })}
      </Head>

      <Div className="relative">
        <IndividualsNavbar text={t('im_employer')} />
        <div className="flex justify-content-end">
          <BgImage bgImage={YellowCircle.src} className="block" />
        </div>
        <IndividualsHero t={t} heroImage={HeroImage} page="/" />
        <ReviewsCarousel type={0} />
      </Div>
      <IndividualsJoinUs t={t} />
      <Div>
        <div className="individuals_promo-container">
          <Image src={Ellipse1} alt="" className="individuals_promo-image-1" />
          <div className="flex gap-5 flex-column xl:flex-row">
            <div className="individuals_promo-title">{t('embrace')}</div>
            <div className="flex flex-column gap-5 justify-content-center">
              <div className="individuals_promo-description">{t('update_profile')}</div>
              <DownloadApp text={t('download_app_now')} color="#FFDF35" />
            </div>
          </div>
          <div className="w-full">
            <Image src={Ellipse2} alt="" className="individuals_promo-image-2" />
          </div>
        </div>
        <IndividualsFeatures featuresList={featuresList} />
      </Div>
      <IndividualsHedera t={t} />
      <Div>
        <div className="flex justify-content-between md:align-items-center mb-5 md:mb-0 mt-2 md:mt-0 md:my-7 flex-column md:flex-row">
          <div className="explore-title text-left">{t('explore_our_blog')}</div>
          <div className="flex ">
            <Link href="https://blog.seaplify.com/" passHref legacyBehavior>
              <a target="_blank">
                <Button variant="primary" size="large" className="mt-3 md:mt-0">
                  {t('view_all_blog_posts')}
                </Button>
              </a>
            </Link>
          </div>
        </div>
        <IndividualsBlog blogList={blogList} t={t} />
      </Div>
      <IndividualsFooter t={t} />
    </div>
  )
}

export async function getServerSideProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'ladning-page-individuals'])),
      // Will be passed to the page component as props
    },
  }
}

const Div = styled.div`
  padding: 1rem;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;

  .normal-text {
    font-size: 20px;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.grey_100};
  }

  .individuals_promo-container {
    background-color: #382b73;
    border-radius: 2rem;
    margin: 0 auto;
    width: 100%;
    max-width: 1352px;
    height: 472px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.white};
    padding-inline: 115px;
    margin-top: -255px;

    .buttons-div {
      flex-direction: row;
    }

    .individuals_promo-image-1,
    .individuals_promo-image-2 {
      position: absolute;
    }

    .individuals_promo-image-1 {
      margin-top: -382px;
    }

    .individuals_promo-image-2 {
      margin-top: 47px;
      margin-left: -55px;
    }

    .individuals_promo-title {
      font-size: 40px;
      font-weight: 700;
    }

    .individuals_promo-description {
      font-weight: 400;
      font-size: 16px;
    }

    .individuals_promo-download {
      font-weight: 700;
      font-size: 18px;
      color: ${({ theme }) => theme.colors.primary_100};
    }

    @media screen and (max-width: 1615px) {
      .individuals_promo-image-1,
      .individuals_promo-image-2 {
        display: none;
      }

      .individuals_promo-title {
        font-size: 28px;
      }
    }

    @media screen and (max-width: 992px) {
      height: 100%;
      padding: 2rem;
    }

    @media screen and (max-width: 425px) {
      padding: 1.5rem;
      height: 530px;
    }

    @media screen and (max-width: 380px) {
      padding: 1rem;
      height: 590px;

      .buttons-div {
        flex-direction: column;
      }
    }
  }

  .explore-title {
    font-weight: 700;
    font-size: 34px;
    max-width: 550px;
  }

  .explore_blog-card {
    width: 100%;
  }

  .explore_blog-date {
    font-size: 15px;
  }

  @media screen and (max-width: 1550px) {
    .normal-text {
      padding-right: 0px;
    }
  }

  @media screen and (max-width: 1100px) {
    padding: 1rem 100px;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;

    .normal-text {
      font-size: 18px;
    }

    .explore-title {
      font-size: 30px;
    }
  }

  @media screen and (min-width: 768px) {
    padding-inline: 3rem;

    .footer-showcase .container {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
`

const BgImage = styled.div<{ bgImage: string }>`
  background-repeat: no-repeat;
  position: absolute;
  z-index: -1;
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  width: 831px;
  height: 549px;
  background-size: 831px 549px;
  top: -80px;
  right: -200px;

  @media screen and (max-width: 376px) {
    width: 138px;
    height: 211px;
    background-size: 268px 211px;
    top: -56px;
    right: -16px;
  }

  @media screen and (min-width: 377px) and (max-width: 576px) {
    width: 138px;
    height: 211px;
    background-size: 268px 211px;
    top: -56px;
    right: -16px;
  }

  @media screen and (min-width: 577px) and (max-width: 768px) {
    width: 500px;
    height: 370px;
    background-size: 500px 370px;
    top: -56px;
    right: 10px;
  }

  @media screen and (min-width: 769px) and (max-width: 1200px) {
    width: 550px;
    height: 418px;
    background-size: 550px 420px;
    top: -56px;
    right: -90px;
  }
`

LandingPageIndividuals.getLayout = (page: React.ReactNode | React.ReactNode[]) => page

export default LandingPageIndividuals
